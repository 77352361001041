@import url(https://fonts.googleapis.com/css2?family=Lato:wgh@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

label {
  width: 100%;
  font-weight: 600;
  color: black;
  border-bottom: 1px solid black;
}

.border-manual {
  border: 1px solid black;
}

.btn-manual {
  outline: none !important;
  border: none !important;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  background: yellow;
  padding: 10px;
  width: 100%;
  height: 80%;
}

.row-manual {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px 0;
}

.row-manual p span {
  font-weight: bold;
}

.final-form {
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
}

.final-form .form-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.final-form .form-group label {
  width: 50% !important;
  margin-top: 20px;
}

.mid-form {
  background: ghostwhite;
  padding-top: 10px;
  padding-bottom: 10px;
}

.check-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ghostwhite;
}

.check-box div {
  width: 100%;
}

.check-box div input {
  margin-top: 12px;
  margin-left: 5px;
}

table {
  width: 100%;
}

table thead {
  border: 1px solid black;
}

table thead th {
  border: 1px solid black;
  padding: 5px !important;
}

table tr {
  border: 1px solid black;
}

table tr td {
  padding: 5px !important;
  border: 1px solid black;
}

.display-div {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  justify-content: space-around;
}

.display-div .btn-div {
  margin-top: 25px;
}

.display-div .btn-div button {
  width: 150px;
}

.price-container {
  background-color: ghostwhite;
  border: 1px solid black;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-container div {
  margin: 15px 0px;
}

.price-container div p {
  font-weight: bold;
  letter-spacing: 1px;
}

.price-container div p span {
  color: orange;
  margin-left: 10px;
}

.card-col {
  border: 1px solid gray;
  background: ghostwhite;
  padding: 30px;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-col i {
  font-size: 58px;
  color: orange;
  margin: 10px 0;
}

.card-col p {
  margin: 5px 0;
  font-size: 18px;
  color: black;
  font-weight: 600;
}

.card-col:hover {
  background: wheat;
}

nav {
  background: green;
  color: white !important;
}

nav a {
  color: white;
}

nav a:hover {
  color: black;
}

.btn.btn-primary {
  background: green !important;
  color: white !important;
}

footer {
  background-color: #020509;
  color: white;
  margin-top: 0px;
}

footer a {
  color: white;
}

footer a:hover {
  color: white !important;
  text-decoration: underline;
}

#loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background: url(/static/media/loader.8be1e9da.gif) no-repeat center center rgba(0, 0, 0, 0.25);
}

.accordion_manual_heading_sidebar {
  color: #3ea175;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 6px 10px;
  font-weight: 700;
  cursor: pointer;
  font-size: 18px;
  display: inline-block;
}

.accordion_manual_heading_sidebar:hover{
  color: #3ea175;
}

.sidebar__menu{
  min-height: 100vh;
}

/*# sourceMappingURL=App.css.map */
* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

body {
  box-sizing: border-box;
  font-family: 'Lato',sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 18px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.container-manual {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
      grid-template-areas: "Sidebar nav nav nav" "sidebar main main main";
}

@media only screen and (max-width: 978px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
        grid-template-areas: "nav" "main";
  }
}

.navbar {
  background: #ffffff;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  border-bottom: 1px solid lightgray;
}

.nav_icon {
  display: none;
}

.nav_icon i {
  font-size: 26px;
  color: #a5aaad;
}

.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: #a5aaad;
  font-size: 15px;
  font-weight: 700;
}

.navbar__left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}

.navbar__right {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar__right a {
  margin-left: 20px;
  text-decoration: none;
}

.navbar__right a i {
  color: #a5aaad;
  font-size: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  padding: 7px;
}

.navbar__img__logo {
  width: 100px;
  height: 50px;
  object-fit: contain;
  justify-content: flex-start;
}

@media only screen and (max-width: 978px) {
  .nav_icon {
    display: inline;
  }
}

@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
}

#sidebar {
  background: #020509;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  transition: all 0.5s;
  width: 24%;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
}

.sidebar__title i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0px 10px;
  font-weight: 700;
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.sidebar__link a {
  text-decoration: none !important;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__link i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__responsive {
  display: inline;
  z-index: 999;
  left: 0;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }
  .sidebar__title > i {
    display: inline;
  }
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

main {
  background: #f3f4f6;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: main;
  overflow-y: auto;
}

.main__container {
  padding: 20px 35px;
  width: 100%;
  margin-top: 50px;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  margin: 20px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 170px;
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.card__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__inner > span {
  font-size: 25px;
}
/*# sourceMappingURL=Panel.css.map */
